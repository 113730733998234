import * as moment from "moment";
import {NgForm} from "@angular/forms";
import {ElementRef} from "@angular/core";
import {HttpHeaders} from "@angular/common/http";
import {AuthService} from '../../shared/services/auth.service';
// import {AdminService} from '../../shared/services/admin.service';

/**
 * Created by Jose on 18/05/2017.
 */

export class Helpers {


    static requestAuthOptions(): {headers: HttpHeaders, params?: any} {

        let pgalUser = JSON.parse(localStorage.getItem('pgalUser'));
        if (pgalUser && pgalUser.token) {
            // let headers      = new Headers({ 'Content-Type': 'application/json' }); // ... Set content type to JSON
            let headers = new HttpHeaders({ 'Authorization': 'Bearer ' + pgalUser.token , 'Content-Type': 'application/json'});
            return { headers: headers, params: null };
        }
    }

    static commonHeaders(guard=null):HttpHeaders {
        let pgalUser = AuthService.userLocal();
        if(guard === null) {
            guard = pgalUser ? pgalUser.guard : null;
        }
        let headers = new HttpHeaders();
        headers = headers
            .set('Content-Type', 'application/json; charset=utf-8');
        if(guard) {
            headers = headers
                .set('App-Guard',guard);
        }
        if (pgalUser && pgalUser.token) {
            headers  = headers
                .set('Authorization', 'Bearer '+pgalUser.token);
        }
        return headers;
    }

    static objToUrlRequestParams(obj:any):URLSearchParams{
        let params = new URLSearchParams();
        //Recorremos las propiedades del objeto

        if(obj){
            Object.keys(obj).map(function(objectKey, index) {
                let value = obj[objectKey];
                params.set(objectKey,value);
            });
        }
        return params;
    }

    static parseDate(dateToParse:any):Date{
        if(dateToParse){
            let constrName=dateToParse.constructor.name;
            if(constrName=='Date' || dateToParse.date){
                if(constrName=='Date'){
                    return  dateToParse;
                }
                let parsedDate= moment(dateToParse.date).toDate();
                return parsedDate;
            } else if(constrName=='String') {
                return moment(dateToParse).toDate();
            }
        }
        return null;

    }

    static parseDateToString(dateToParse:any, format: string):string{
        if(dateToParse){
            if(format) {
                return moment(dateToParse).format(format);
            }
            return moment(dateToParse).toDate().toString();
        }
        return null;

    }

    static resetForm(form:NgForm,el?:ElementRef){
        form.resetForm();
        if(el){
            el.nativeElement.querySelector(':focus').blur();
        }

    }

    //Copia modelos (Debe de estar bien el constructor)
    static copy (source,destination){
        // console.log("source",source);
        // console.log("destination",destination);
        if(source.constructor.name!==destination.constructor.name){
            throw "the class of source and destination are different";
        }
        destination.constructor(source);
        return destination;
    }

    static clone<T>(instance: T): T {
        const copy = new (instance.constructor as { new (): T })();
        Object.assign(copy, instance);
        return copy;
    }

    static getUploader (entity, entity_id?){

        let conf:any =  {};
        switch (entity){
            case 'product':
                conf.type = 'PRODUCT_IMAGE';
                conf.product_id = entity_id;
                break;
            default: throw "Entity not found";
        }
        return conf;

    }

    static parseFileSize(size: number, decimals: number = 2, to: string = 'kB') {
        let num = 0;
        switch (to) {
            case 'kB':
                num = size / Math.pow(10,3); break;
            case 'MB':
                num = size / Math.pow(10,6); break;
            case 'GB':
                num = size / Math.pow(10,9); break;
            case 'KiB':
                num = size / Math.pow(2,10); break;
            case 'MiB':
                num = size / Math.pow(2,20); break;
            case 'GiB':
                num = size / Math.pow(2,30); break;
            case 'TiB':
                num = size / Math.pow(2,40); break;
        }
        return num.toFixed(decimals);
    }

    static updateObject(obj: object, data: object) {
        Object.keys(data).forEach(k => {
            if(data[k]) {
                obj[k] = data[k];
            }
        });
    }


}
