import {Injectable, TemplateRef} from '@angular/core';
import {
    ConfirmationDialogComponent, ErrorDialogComponent,
    ErrorValidationDialogComponent
} from "../dialogs/dialog.component";
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';

@Injectable()
export class AlertService {
    public action:string = 'X';
    constructor(
        private snackBar: MatSnackBar,
        private snackBarConfig : MatSnackBarConfig,
        private dialog: MatDialog,
        private translateService: TranslateService
    ) { }


    msg(msg:string,duration:number=2000){
        this.snackBarConfig.duration=duration;
        this.snackBar.open(this.translateService.instant(msg),this.action,{duration:duration});
    }

    irtMsg(msg:string,duration:number=2000){
    this.snackBarConfig.duration=duration;
    this.snackBar.open(this.translateService.instant(msg),this.action,{
        duration: duration,
        horizontalPosition: 'right',
        verticalPosition: 'top',
    });
    }

    genericError(err=null){
        if(err){
           console.log(err);
        }
        this.msg('Se produjo un error.');
    }

    genericSaved(){
        this.msg('Guardado');
    }

    alertError(text:string,title?:string): MatDialogRef<any> {
        let config = new MatDialogConfig();
        config.width = '600px';
        let modal = this.dialog.open(ErrorDialogComponent,config);
        modal.componentInstance.data={title:title?title:'Ha ocurrido un error',text:text};
        return modal;
    }

    alertValidationError(errors,title?):MatDialogRef<ErrorValidationDialogComponent>{
        let modal = this.modal(ErrorValidationDialogComponent,'sm');
        modal.componentInstance.data={title:title?title:'Los datos introducidos no son correctos. Revisa los errores.'};
        modal.componentInstance.errors=errors;
        return modal;
    }

    confirmation(text:string,param?:string):MatDialogRef<ConfirmationDialogComponent>{
      let modal = this.modal(ConfirmationDialogComponent,'custom',{width:'400px'});
      modal.componentInstance.data={msg:{text:text, title: param ? param : '¿Estás seguro?'}};
      return modal;
    }

    success(title: string, text?: string) {
      let modal = this.modal(ConfirmationDialogComponent,'custom',{width:'400px'});
      modal.componentInstance.data={title, text: text || 'msg.dialog_confirmation'};
      modal.componentInstance.confirmed.subscribe(res => {
        if(res){
          modal.close();
        }
      });
      return modal;
    }

    modal(ref,size?:string,custom?:any,data?:any): MatDialogRef<any> {
        let sizes = {
            custom : custom,
            sm : {width:'600px'},
            md : {width:'50%',height:'60%'},
            lg : {width:'80%',height:'80%'},
            xl : {width:'100%',height:'80%'},
            full : {width:'100%',height:'98%'},
        };
        let res = sizes[size];

        let config = new MatDialogConfig();
        if(res){
            config.width=res.width;
            config.height=res.height;
        }else{
            config.width='100%';
            config.height='70%';
        }
        config.data=data;
        let modal =  this.dialog.open(ref,config);
        return modal;
    }
    loader(status:boolean){
        // this.loaderService.set(status);
    }
}
