import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from '../../../shared/services/auth.service';

@Injectable()
export class UnauthenticatedGuard implements CanActivate {

    constructor (private router: Router){}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        let user = AuthService.userLocal();
        // console.log('Unauth', user);
        if (user && user.token) {
            if (user.guard === 'admins') {
                return this.router.navigate(['/admin']);
            } else if (user.guard === 'carriers') {
                return this.router.navigate(['/carrier']);
            } else if (user.guard === 'clients') {
                return this.router.navigate(['/']);
            }
        }
        return true;
    }

    canShow() {
        let user = AuthService.userLocal();
        return !user;
    }
}