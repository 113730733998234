import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {Helpers} from '../../main/config/helpers';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private baseEndPoint = environment.baseEndPoint + '/upload';

  constructor(
    private http: HttpClient
  ) { }

  requestDownload(id: number) {
    return this.http.get(this.baseEndPoint + '/' + id + '/request-download', { headers: Helpers.commonHeaders() });
  }

  requestUrl(id: number) {
    return this.http.get(this.baseEndPoint + '/' + id + '/request-url', { headers: Helpers.commonHeaders() });
  }

  delete(id: number) {
    return this.http.delete(this.baseEndPoint + '/' + id, { headers: Helpers.commonHeaders() })
  }

  destroyGenericBySrc(src:string){
    return this.http.post(this.baseEndPoint+'/delete-gen-by-src',{src:src},{headers:Helpers.commonHeaders()});
  }
}
