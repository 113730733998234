import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AlertService} from './services/alert.service';
import {
  ConfirmationDialogComponent, ErrorDialogComponent,
  ErrorValidationDialogComponent
} from './dialogs/dialog.component';
import {TranslateModule} from '@ngx-translate/core';
import {SharedModule} from '../shared.module';
import {MaterialModule} from '../../material/material.module';
import {HttpClientModule} from '@angular/common/http';
import {MatSnackBar, MatSnackBarConfig} from '@angular/material/snack-bar';


@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    //FormsModule,
    //ReactiveFormsModule,
    BrowserModule,
    //FormsModule,
    //ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    TranslateModule,
    SharedModule
  ],
  entryComponents:[
   ErrorDialogComponent,
   ErrorValidationDialogComponent,
   ConfirmationDialogComponent
  ],
  declarations: [ErrorDialogComponent,ErrorValidationDialogComponent,ConfirmationDialogComponent],
  exports:[],
  providers: [
      AlertService,
      MatSnackBar,
      MatSnackBarConfig
  ]

})
export class AlertModule {

}
