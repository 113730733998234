import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from '../../../shared/services/auth.service';
/**
 * Created by Jose on 15/05/2017.
 */

@Injectable()
export class CarrierGuard implements CanActivate {

    constructor (private router : Router){}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        let user = AuthService.userLocal();
        if(user) {
            if (user.token && user.guard === 'carriers'){
                return true;
            }
        }
        return this.router.navigate(['/auth/carrier/login'], {queryParams: {returnUrl: state.url}});
    }

    canShow() {
        let user = AuthService.userLocal();
        return user.token && (user.guard === 'carriers');
    }
}