import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Helpers} from '../../main/config/helpers';
import {Admin} from '../models/users/admins/admin';
import {PaginationResponse} from '../models/pagination/pagination-response.model';
import {ClientAddress} from "../models/users/clients/client-address";


//import { User } from '../_models/index';

@Injectable()
export class AdminService {

    private baseEndPoint = environment.baseEndPoint;

    constructor(private http: HttpClient) { }

    getSubClients(id){
      return this.http.get(this.baseEndPoint + '/admin/sub-clients/' + id, {headers: Helpers.commonHeaders()});
    }

    addSubClient(id, email){
      const data = {
        email: email,
        id: id,
      };
      return this.http.post(this.baseEndPoint + '/admin/add-sub-client', data, {headers: Helpers.commonHeaders()});
    }

    removeSubClient(id, email){
      return this.http.get(this.baseEndPoint + '/admin/remove/subclient/' + id + '/' + email, {headers: Helpers.commonHeaders()});
    }

    changeLang(lang:string){
        return this.http.post(this.baseEndPoint+'/profile/lang/' + lang,{},{headers: Helpers.commonHeaders()});
    }

    index() {
        return this.http.get<Admin[]>(this.baseEndPoint + '/admin', {headers: Helpers.commonHeaders()});
    }

    show(id: number, options?: any) {
        return this.http.get<Admin>(this.baseEndPoint + '/admin/' + id,{headers: Helpers.commonHeaders(), params: options})
    }

    updatePassword(admin: number, data){
        return this.http.put(this.baseEndPoint + '/admin/' + admin + '/updatePassword', data, {headers: Helpers.commonHeaders()});
    }

    pagination(page: number, options?: any) {
        options = options ? options : {};
        options.page = page;
        return this.http.get<PaginationResponse>(this.baseEndPoint + '/admin/pagination', {headers: Helpers.commonHeaders(), params: options});
    }

    get(admin: number) {
        return this.http.get<Admin>(this.baseEndPoint + '/admin/' + admin, {headers: Helpers.commonHeaders()});
    }

    create(admin: Admin) {
        return this.http.post(this.baseEndPoint + '/admin', {admin}, {headers: Helpers.commonHeaders()});
    }

    update(admin: Admin) {
        return this.http.put(this.baseEndPoint + '/admin/'+admin.id, {admin}, {headers: Helpers.commonHeaders()});
    }

    destroy(admin: number) {
        return this.http.delete(this.baseEndPoint + '/admin/' + admin, {headers: Helpers.commonHeaders()});
    }

    toggleStatus(admin: number) {
        return this.http.put(this.baseEndPoint + '/admin/' + admin + '/status/toggle',null,{headers: Helpers.commonHeaders()})
    }

    clientAdresses(client: number){
        return this.http.get(this.baseEndPoint + '/client/' +  client + '/address', {headers: Helpers.commonHeaders()});
    }

    setClientMainAddress(address: number) {
        return this.http.put(this.baseEndPoint + '/client/' + address + '/main-address', {address}, {headers: Helpers.commonHeaders()});
    }

    storeClientAddress(address: any, client: number) {
        return this.http.post(this.baseEndPoint + '/client/' + client + '/address', {address}, {headers: Helpers.commonHeaders()});
    }

    pickups(page: number, options?: any){
        return this.http.get(this.baseEndPoint + '/pickups?page='+page, {headers: Helpers.commonHeaders(),params: options});
    }

    clientList(options?: any){
        return this.http.get(this.baseEndPoint + '/client/list', {headers: Helpers.commonHeaders(),params: options});
    }

    storePickup(data: any){
        return this.http.post(this.baseEndPoint + '/pickup', data, {headers: Helpers.commonHeaders()});
    }

    paginationPickup(data: any, page: number) {
        return this.http.get(this.baseEndPoint + '/admin/pickup/pagination?page=' + page, {headers: Helpers.commonHeaders(), params: data});
    }

}
