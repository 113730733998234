import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatCardModule} from '@angular/material/card';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatMenuModule} from '@angular/material/menu';



@NgModule({
  declarations: [],
  exports: [
      MatButtonModule,
      MatSidenavModule,
      MatToolbarModule,
      MatCardModule,
      MatSlideToggleModule,
      MatIconModule,
      MatListModule,
      MatProgressSpinnerModule,
      MatFormFieldModule,
      MatDialogModule,
      MatSnackBarModule,
      MatCheckboxModule,
      MatInputModule,
      MatSelectModule,
      MatProgressBarModule,
      MatExpansionModule,
      MatTooltipModule,
      MatMenuModule,
  ]
})
export class MaterialModule { }
