import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../main/config/helpers';
import {PaginationResponse} from '../models/pagination/pagination-response.model';
import {Admin} from '../models/users/admins/admin';
import {Waste} from '../models/pickups/waste';
import {Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class WasteService {

    private baseEndPoint = environment.baseEndPoint + '/pickup/waste';

    constructor(private http: HttpClient) { }

    pagination(options?: any): Observable<PaginationResponse> {
        return this.http.get<PaginationResponse>(this.baseEndPoint + '/pagination', {headers: Helpers.commonHeaders(), params: options});
    }

    index(options?: any) {
        return this.http.get(this.baseEndPoint, {headers: Helpers.commonHeaders(), params: options});
    }

    /*get(waste: number) {
        return this.http.get<Waste>(this.baseEndPoint + waste, {headers: Helpers.commonHeaders()});
    }*/

    get(waste: number) {
        return this.http.get<Waste>(this.baseEndPoint + '/' + waste, {headers: Helpers.commonHeaders()});
    }

    create(waste: Waste) {
        return this.http.post(this.baseEndPoint, {waste}, {headers: Helpers.commonHeaders()});
    }

    show(waste: number, options?: any) {
        return this.http.get<Waste>(this.baseEndPoint + '/' + waste,{headers: Helpers.commonHeaders(), params: options});
    }

    update(waste: Waste) {
        return this.http.put(this.baseEndPoint + '/'+waste.id, {waste}, {headers: Helpers.commonHeaders()});
    }

    delete(id:number) {
        return this.http.delete(this.baseEndPoint + '/' + id, {headers: Helpers.commonHeaders()});
    }
}
