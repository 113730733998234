import { FuseNavigation } from '@fuse/types';
import {CarrierGuard} from '../main/config/guards/carrier.guard';
import {ClientGuard} from '../main/config/guards/client.guard';
import {AdminGuard} from '../main/config/guards/admin.guard';

export const navigation: FuseNavigation[] = [
    /*{
        id       : 'applications',
        title    : 'Applications',
        translate: 'NAV.APPLICATIONS',
        type     : 'group',
        children : [
            {
                id       : 'sample',
                title    : 'Sample',
                translate: 'NAV.SAMPLE.TITLE',
                type     : 'item',
                icon     : 'email',
                url      : '/sample',
                badge    : {
                    title    : '25',
                    translate: 'NAV.SAMPLE.BADGE',
                    bg       : '#F44336',
                    fg       : '#FFFFFF'
                }
            }
        ]
    },*/
    {
        id          : 'cli-dashboard',
        title       : 'Inicio',
        // translate: 'NAV.SAMPLE.TITLE',
        type        : 'item',
        icon        : 'view-dashboard',
        url         : '/',
        exactMatch  : true,
        guards: [
            ClientGuard
        ],
    },
    {
        id       : 'cli-pickups',
        title    : 'Recogidas',
        // translate: 'NAV.APPLICATIONS',
        type     : 'collapsable',
        icon        : 'truck-fast',
        guards: [
            ClientGuard
        ],
        children : [
            {
                id          : 'cli-pickups-index',
                title       : 'Mis recogidas',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'format-list-bulleted-square',
                url         : '/pickups',
                exactMatch  : true,
            },
            {
                id          : 'cli-new-pickup',
                title       : 'Nueva recogida',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'plus',
                url         : '/pickups/new',
                exactMatch  : true,
            },
            {
                id          : 'cli-certificate',
                title       : 'Certificados',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'stamper',
                url         : '/certificate',
                exactMatch  : false,
            },
        ]
    },
    {
        id          : 'cli-profile',
        title       : 'Mi cuenta',
        // translate: 'NAV.SAMPLE.TITLE',
        type        : 'item',
        icon        : 'account',
        url         : '/profile',
        exactMatch  : true,
        guards: [
            ClientGuard
        ],
    },
    {
        id          : 'car-dashboard',
        title       : 'Inicio',
        // translate: 'NAV.SAMPLE.TITLE',
        type        : 'item',
        icon        : 'view-dashboard',
        url         : '/carrier',
        exactMatch  : true,
        guards: [
            CarrierGuard
        ],
    },
    {
        id          : 'car-pickups',
        title       : 'Recogidas',
        // translate: 'NAV.SAMPLE.TITLE',
        type        : 'item',
        icon        : 'truck-fast',
        url         : '/carrier/pickups',
        exactMatch  : false,
        guards: [
            CarrierGuard
        ],
    },
    {
        id          : 'adm-dashboard',
        title       : 'Inicio',
        // translate: 'NAV.SAMPLE.TITLE',
        type        : 'item',
        icon        : 'view-dashboard',
        url         : '/admin',
        exactMatch  : true,
        guards: [
            AdminGuard
        ],
    },
    {
        id          : 'admin-pickup-group',
        title       : 'Recogidas',
        // translate: 'NAV.APPLICATIONS',
        type        : 'group',
        guards: [
            AdminGuard
        ],
        children : [
            {
                id          : 'adm-pickups',
                title       : 'Recogidas',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'truck-fast',
                url         : '/admin/pickups',
                exactMatch  : false,
            },
            {
                id          : 'adm-certificate',
                title       : 'Certificados',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'stamper',
                url         : '/admin/certificates',
                exactMatch  : false,
            },
        ],
    },
    {
        id          : 'admin',
        title       : 'Administración',
        // translate: 'NAV.APPLICATIONS',
        type        : 'group',
        guards: [
            AdminGuard
        ],
        children : [
            {
                id          : 'adm-admins',
                title       : 'Administradores',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'account-star',
                url         : '/admin/admins',
                exactMatch  : false,
            },
            {
                id          : 'adm-clients',
                title       : 'Clientes',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'account',
                url         : '/admin/clients',
                exactMatch  : false,
            },
            {
                id          : 'adm-carriers',
                title       : 'Transportistas',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'account-hard-hat',
                url         : '/admin/carriers',
                exactMatch  : false,
            },
            {
                id          : 'adm-wastes',
                title       : 'Códigos LER',
                // translate: 'NAV.SAMPLE.TITLE',
                type        : 'item',
                icon        : 'recycle',
                url         : '/admin/wastes',
                exactMatch  : false,
            },
        ],
    },
];
