import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {AlertService} from './alerts/services/alert.service';
import {MatSnackBarConfig} from '@angular/material/snack-bar';
import {SearchPipe} from './pipes/search.pipe';
import {UploadModule} from './uploads/upload.module';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {AiPaginationTemplateComponent} from './components/pagination/pagination-template/pagination-template.component';
import {CustomThComponent} from './components/custom-th/custom-th.component';
import {SimpleImgViewerComponent} from './components/simple-img-viewer/simple-img-viewer.component';
import {NestableOptGroupComponent} from './components/nestable-opt-group/nestable-opt-group.component';
import {NestableSelectComponent} from './components/nestable-select/nestable-select.component';
import {TinymceEditorComponent} from './components/tinymce-editor/tinymce-editor.component';
import {FormsModule} from '@angular/forms';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgxJsonViewerModule} from 'ngx-json-viewer';
import {MaterialModule} from '../material/material.module';
import {MomentModule} from 'angular2-moment';
import {ClipboardModule} from 'ngx-clipboard';
import {ObjNgForPipe} from './pipes/obj-ng-for.pipe';
import {AdminService} from './services/admin.service';
import {ClientService} from './services/client.service';
import {WasteService} from './services/waste.service';
import {StateService} from './services/state.service';
import { DPrivacyPolicyComponent } from './components/d-privacy-policy/d-privacy-policy.component';
import {ConfigurationService} from "./services/configuration.service";



@NgModule({
    declarations: [
        SearchPipe,
        ObjNgForPipe,

        LoadingSpinnerComponent,
        AiPaginationTemplateComponent,
        CustomThComponent,
        SimpleImgViewerComponent,
        NestableOptGroupComponent,
        NestableSelectComponent,
        TinymceEditorComponent,
        DPrivacyPolicyComponent,
    ],
    imports: [
        CommonModule,
        UploadModule,
        // TranslateModule,
        FormsModule,
        NgxPaginationModule,
        NgxJsonViewerModule,
        MaterialModule,
        //MdiIconModule
    ],
    exports: [
        SearchPipe,
        ObjNgForPipe,

        UploadModule,
        LoadingSpinnerComponent,
        MomentModule,
        NgxPaginationModule,
        // TranslateModule,

        AiPaginationTemplateComponent,
        CustomThComponent,
        ClipboardModule,
        SimpleImgViewerComponent,
        NgxJsonViewerModule,
        NestableOptGroupComponent,
        NestableSelectComponent,
        TinymceEditorComponent,
    ],
    providers: [
        MatSnackBarConfig,
        AlertService,
        AdminService,
        ClientService,
        WasteService,
        StateService,
        ConfigurationService
    ],
    entryComponents: [
        DPrivacyPolicyComponent,
    ]
})
export class SharedModule { }
