<div>
  <mat-dialog-content>
    <mat-icon  class="orange-400-fg">information</mat-icon>
    <!--<h3 class="title" *ngIf="data.title">{{ data.title | translate}}</h3>-->
    <p class="title" *ngIf="data && data.msg && data.msg.title">{{ data.msg.title | translate}}</p>
    <div *ngIf="data && data.msg">{{data.msg.text | translate}}</div>

  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button  mat-raised-button color="primary" class="btn-w-md mr-10" (click)="confirmed$.next(true)" [mat-dialog-close]="true">{{'Aceptar'|translate}}</button>
    <button mat-raised-button  class="btn-w-md" mat-dialog-close>{{'Cancelar'|translate}}</button>
  </mat-dialog-actions>
</div>
