import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../main/config/helpers';

@Injectable({
    providedIn: 'root'
})
export class StateService {

    private baseEndPoint = environment.baseEndPoint + '/pickup/state';

    constructor(private http: HttpClient) { }

    index(options?: any) {
        return this.http.get(this.baseEndPoint, {headers: Helpers.commonHeaders(), params: options});
    }
}
