import {Injectable, NgZone} from '@angular/core';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {Admin} from '../models/users/admins/admin';
import {Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Helpers} from '../../main/config/helpers';
import {AdminService} from './admin.service';
import {Authenticatable} from '../models/users/authenticatable';
import {Client} from '../models/users/clients/client';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private baseEndPoint = environment.baseEndPoint;

    private logger = new Subject<boolean>();
    private loggedUser: BehaviorSubject<Authenticatable>;
    public loggedUser$ : Observable <Authenticatable>;
    private loginError: BehaviorSubject<any> = new BehaviorSubject(null);
    public loginError$ : Observable <any>  = this.loginError.asObservable();
    private userInfoChanged: BehaviorSubject<Authenticatable>;
    public userInfoChanged$ : Observable <Authenticatable>;

    constructor(
        private http: HttpClient,
        private  router: Router,
        private zone: NgZone) {
        this.isLoggedIn().subscribe(d => {
            if(d==true){
                let u = AuthService.userLocal();
                //console.log('isLoggedIn', u);
                this.loggedUser.next(u);
                //console.log("Logeado");
            }else if(d==false){
                this.loggedUser.next(null);
            }
        });
        //Si hay un token almacenado en el navegador obtiene el usuario
        // if (AuthService.userLocal().token){
        //     this.loggedUser = new BehaviorSubject(new Authenticatable(JSON.parse(localStorage.getItem('pgalUser'))));
        //     this.authUser().subscribe((user: any) =>{
        //         user.token =  AuthService.userLocal().token;
        //         this.loggedUser.next(user);
        //     });
        // } else {
            this.loggedUser = new BehaviorSubject(null);
        // }
        this.userInfoChanged = new BehaviorSubject(null);
        this.userInfoChanged$ = this.userInfoChanged.asObservable();
        this.loggedUser$ = this.loggedUser.asObservable();
    }

    handleError(error) {
        this.router.navigate(['/auth/login']);
        return Observable.throw(error);
    }

    updateUserInfo(user: Authenticatable): void {
        let stored = AuthService.userLocal();
        Object.keys(user).forEach(k => {
            stored[k] = user[k];
        });
        AuthService.storeUser(stored);
        this.userInfoChanged.next(stored);
    }

    processLogin(email: string, password: string, guard = 'client'): Subscription {
        return this.login(email, password, guard)
            .subscribe(data => {
                if (data) {
                    let userObj = new Authenticatable(data);
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    AuthService.storeUser(userObj);
                    this.logger.next(true);
                    // this.loggedUser.next(userObj);
                } else {
                }
            }, (err: ErrorEvent) => {
                this.loginError.next(err);
            });
    }

    static storeUser(userObj: Authenticatable) {
        localStorage.setItem('pgalUser', JSON.stringify(userObj.toLocalstorage()));
    }

    login(email: string, password: string, guard='clients') {
        return this.http.post(environment.baseEndPoint+'/auth', JSON.stringify({ email: email, password: password }),{headers:Helpers.commonHeaders(guard)});
    }

    logout() {
        // remove user from local storage to log user out
        let user = AuthService.userLocal();
        localStorage.removeItem('pgalUser');
        // this.fuseNavigationService.checkMainNavigation();
        this.logger.next(false);
        if(user) {
            if(user.guard === 'admins') {
                this.router.navigate(['/auth/admin/login']);
            } else if(user.guard === 'carriers') {
                this.router.navigate(['/auth/carrier/login']);
            } else if(user.guard === 'clients') {
                this.router.navigate(['/auth/login']);
            }
        }
    }

    isLoggedIn():Observable<boolean>{
        return this.logger.asObservable();
    }

    //Actualizar usuario logeado
    updateLoggedUser(){

        let u = JSON.parse(localStorage.getItem('pgalUser'));
        let token  = u.token;
        this.http.get(this.baseEndPoint+'/auth/user',{headers: Helpers.commonHeaders()})
        /*.map((response: Response) => response.json()).subscribe(userNew => {
            let userObj=new User(userNew);
            userObj.token=token;
            localStorage.setItem('pgalUser',JSON.stringify(userObj.toLocalstorage()));
            this.loggedUser.next(userObj);
        })*/;

    }

    authUser(){
        return this.http.get(this.baseEndPoint+'/auth/user', {headers: Helpers.commonHeaders()})
            /*.catch(this.handleError)*/;
    }

    static userLocal(){
        let user = JSON.parse(localStorage.getItem('pgalUser'));
        return new Authenticatable(user);
    }

    /*checkRoles(roles:Array<string>):Observable<boolean>{

        let sub = new Subject<boolean>();
        this.loggedUser$.subscribe(user=>{
            if(!isUndefined(user.roles)){
                user.roles.forEach(userRole => {
                    if(roles.indexOf(userRole.name) !==-1){
                        //Todo buscar porque en stackoverflow
                        setTimeout(()=>sub.next(true),1);
                    }
                });
                setTimeout(()=>sub.next(false),1);
            }
        });

        return sub.asObservable().first();
    }*/

    register(data: any): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.post(environment.baseEndPoint + '/auth/register', data, {headers});
    }

    checkActivated(client: number): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.get(environment.baseEndPoint + '/auth/client/' + client + '/activate', {headers});
    }

    activateClient(client: number, code: string): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.post(environment.baseEndPoint + '/auth/client/' + client + '/activate', {code}, {headers});
    }

    sendToken(client: number): Observable<object> {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.post(environment.baseEndPoint + '/auth/client/' + client + '/resend-token', {}, {headers});
    }

    clientProfile(options?: any): Observable<Client> {
        return this.http.get<Client>(this.baseEndPoint + '/auth/client-profile', {headers: Helpers.commonHeaders(), params: options});
    }

    updateClientProfile(data: any): Observable<Client> {
        return this.http.put<Client>(this.baseEndPoint + '/auth/client-profile', data, {headers: Helpers.commonHeaders()});
    }

    forgotPassword(email: string, role: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.post(this.baseEndPoint + '/auth/forgot-password', {email, role}, {headers});
    }

    resetPassword(token: string, password: string) {
        const headers = new HttpHeaders({ 'Content-Type': 'application/json'});
        return this.http.post(this.baseEndPoint + '/auth/reset-password', {token, password}, {headers});
    }
}
