import {Helpers} from '../../../main/config/helpers';

export class Authenticatable {
    id: number;
    firstname: string;
    lastname: string;
    fullname: string;
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    token: string;
    activated: string;
    guard: string;
    created_at: Date;

    constructor(userInfo?){
        if (userInfo) {
            this.id = userInfo.id;
            this.name = userInfo.name;
            this.firstname = userInfo.firstname;
            this.lastname = userInfo.lastname;
            if(this.name) {
                this.fullname = this.name;
            } else {
                this.fullname = this.firstname;
                if(this.lastname) {
                    this.fullname += ' ' + this.lastname;
                }
            }
            this.email = userInfo.email;
            this.token = userInfo.token;
            if (userInfo.activated) {
                this.activated = userInfo.activated;
            }
            this.guard = userInfo.guard;
            if(userInfo.created_at) {
                this.created_at = Helpers.parseDate(userInfo.created_at);
            }
        }
    }

    toLocalstorage(): {[key: string]: any} {
        return {
            id : this.id,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            name: this.name,
            token: this.token,
            guard: this.guard,
            activated: this.activated,
        };
    }
}
