import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {AuthService} from '../shared/services/auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError((err: HttpErrorResponse) => {
            if(err.status === 401 || err.status === 403) {
                let user = AuthService.userLocal();
                let url = '';
                let guard = request.headers.get('App-Guard') || user.guard;
                switch (guard) {
                    case 'admins':
                        url = '/auth/admin/login'; break;
                    case 'carriers':
                        url = '/auth/carrier/login'; break;
                    default:
                        url = '/auth/login';
                }
                this.router.navigate([url]);
                // return Observable.empty();
            } else {
                return throwError(err);
            }
        }))
    }
}
