import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from '../../../environments/environment';
import {Helpers} from '../../main/config/helpers';
import {Option} from '../models/configuration/option.model';
import {Observable} from 'rxjs';

@Injectable()
export class ConfigurationService {

  constructor(
    private http: HttpClient,
  ) { }

  public baseEndPoint = environment.baseEndPoint+'/option';

  all(options?:any){
    return this.http.get(this.baseEndPoint,{headers:Helpers.commonHeaders(),params:new HttpParams({fromObject:options})});
  }

  get(key: string): Observable<Option> {
      return this.http.get<Option>(this.baseEndPoint + '/' + key, {headers: Helpers.commonHeaders()});
  }

  updateMany(options: Option[]){
    return this.http.put(this.baseEndPoint, {options},{headers:Helpers.commonHeaders()});
  }

  getIp() {
    return this.http.get<any>(this.baseEndPoint + '/get-ip', {headers:Helpers.commonHeaders()});
  }

}
