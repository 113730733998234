<h1 mat-dialog-title>Política de privacidad de&nbsp;<strong>app.plasticosgalian.com</strong></h1>
<mat-dialog-content>
    <p>Esta Aplicación recoge algunos Datos Personales de sus Usuarios.</p>
    <h2 id="owner_of_the_data">Titular y Responsable de Tratamiento de los Datos</h2>
    <hr>
    <h2 id="types_of_data">Clases de Datos recogidos</h2>
    <p>Entre las clases de Datos Personales que esta Aplicación recoge, ya sea directamente o a través de terceros, se encuentran: dirección de correo electrónico, nombre, apellido(s), sexo, fecha de nacimiento, número de teléfono, país, código postal, ciudad, dirección de facturación, idioma, Cookie y Datos de uso.</p>
    <p>La información completa referente a cada categoría de Datos personales que se recogen se proporciona en las secciones de la presente Política de Privacidad dedicadas a tal fin o mediante textos explicativos específicos que se muestran antes de la recogida de dichos Datos.<br> Los Datos Personales podrán ser proporcionados libremente por el Usuario o, en caso de los Datos de Uso, serán recogidos automáticamente cuando usted emplee esta Aplicación.<br>Todos los Datos solicitados por esta Aplicación son obligatorios y la negativa a proporcionarlos podrá hacer que esta Aplicación se vea en la imposibilidad de prestar sus servicios. En los casos en los que esta Aplicación indique específicamente que ciertos Datos no son obligatorios, los Usuarios serán libres de no comunicar tales Datos sin que esto tenga consecuencia alguna sobre la disponibilidad o el funcionamiento del servicio.<br>Los Usuarios que tengan dudas sobre qué Datos son obligatorios pueden contactar con el Titular.<br>El uso de Cookies - o de otras herramientas de seguimiento - por parte de esta Aplicación o por los titulares de servicios de terceros utilizados por esta Aplicación tiene como finalidad la prestación del servicio solicitado por el Usuario, además de cualesquiera otras finalidades que se describan en el presente documento y en la Política de Cookies, en caso de estar disponible.</p>
    <p>El Usuario asume la responsabilidad respecto de los Datos Personales de terceros que se obtengan, publiquen o compartan a través de esta Aplicación y declara por la presente que tiene el consentimiento de dichos terceros para proporcionar dichos Datos al Titular.</p>
    <hr>
    <h2 id="place_of_processing">Modalidad y lugar del Tratamiento de los Datos recogidos</h2>
    <h3>Modalidades de Tratamiento</h3>
    <p>El Responsable de Tratamiento tratará los Datos de los Usuarios de manera adecuada y adoptará las medidas de seguridad apropiadas para impedir el acceso, la revelación, alteración o destrucción no autorizados de los Datos.<br>El tratamiento de Datos se realiza mediante ordenadores y/o herramientas informáticas, siguiendo procedimientos y modalidades organizativas estrictamente relacionadas con las finalidades señaladas. Además del Responsable de Tratamiento, en algunos casos podrán acceder a los Datos ciertas categorías de personas encargadas relacionadas con el funcionamiento de la página (administración, ventas, marketing, departamento jurídico y de administración de sistemas) o contratistas externos que presten servicios al Responsable de Tratamiento (tales como proveedores externos de servicios técnicos, empresas de mensajería, “hosting providers”, empresas de informática, agencias de comunicación) que serán nombrados por el Titular como Encargados del Tratamiento, si fuera necesario. Se podrá solicitar al Responsable de Tratamiento en cualquier momento una lista actualizada de dichas personas.</p>
    <h3>Lugar</h3>
    <p>Los Datos serán tratados en la sede operativa del Responsable de Tratamiento, así como en otros lugares en los que se encuentren situadas las partes que también estén involucradas en dicho tratamiento. Para más información, por favor póngase en contacto con el Responsable de Tratamiento.</p>
    <h3>Período de conservación</h3>
    <p>Los Datos serán tratados durante el plazo necesario para prestar el servicio solicitado por el Usuario, o el que se requiera en función de las finalidades descritas en este documento, y el Usuario tendrá en todo momento la facultad de solicitar la interrupción de su Tratamiento o la cancelación de los Datos.</p>
    <hr>
    <h2 id="use_collected_data">Finalidad del Tratamiento de los Datos recogidos</h2>
    <p>Los Datos relativos al Usuario son recogidos para permitir al Titular la prestación de sus servicios, así como para las siguientes finalidades: Contactar con el Usuario, Registro y autenticación y Estadísticas.</p>
    <p>Los Datos Personales utilizados para cada finalidad son descritos en los apartados específicos de este documento.</p>
    <hr>
    <h2 id="data_processing_detailed_info">Información detallada del tratamiento de los Datos Personales</h2>
    <p>Se recogen Datos Personales para las siguientes finalidades y utilizando los siguientes servicios:</p>
    <div class="for_boxes row">
        <div class="col-xs-12 col-sm-6">
            <div class="box_primary box_10 expand collapsed">
                <h3 class="expand-click w_icon_24 policyicon_purpose_4464281">Registro y autenticación</h3>
                <div class="expand-content">
                    <p>Al registrarse o autenticarse, el Usuario permite que esta Aplicación le identifique y le dé acceso a los servicios dedicados.<br>Dependiendo de lo indicado a continuación, los servicios de registro y autenticación podrán ser prestados por la asistencia de terceros. En tal caso, esta Aplicación podrá acceder a algunos Datos almacenados por el servicio de terceros utilizado para el registro o autenticación.</p>
                    <h4>Registro directo (esta Aplicación)</h4>
                    <p>El Usuario se registra rellenando el formulario de registro y proporcionando sus Datos Personales directamente a esta Aplicación.</p>
                    <p>Datos Personales recogidos: nombre, apellido(s), ciudad, código postal, dirección de correo electrónico, dirección de facturación, fecha de nacimiento, idioma, número de teléfono, país y sexo.</p>
                </div>
            </div>
        </div>
        <div class="col-xs-12 col-sm-6">
            <div class="box_primary box_10 expand collapsed">
                <h3 class="expand-click w_icon_24 policyicon_purpose_4464281">Registro y autenticación</h3>
                <div class="expand-content">
                    <p>Al registrarse o autenticarse, el Usuario permite que esta Aplicación le identifique y le dé acceso a los servicios dedicados.<br>Dependiendo de lo indicado a continuación, los servicios de registro y autenticación podrán ser prestados por la asistencia de terceros. En tal caso, esta Aplicación podrá acceder a algunos Datos almacenados por el servicio de terceros utilizado para el registro o autenticación.</p>
                    <h4>Registro directo (esta Aplicación)</h4>
                    <p>El Usuario se registra rellenando el formulario de registro y proporcionando sus Datos Personales directamente a esta Aplicación.</p>
                    <p>Datos Personales recogidos: nombre, apellido(s), número de teléfono, dirección de correo electrónico, dirección, ciudad, código postal, fecha de nacimiento y país.</p>
                </div>
                <!-- /expand-content -->
            </div>
            <!-- /expand -->
        </div>
    </div>
    <h2 id="further_data_processing_info">Información adicional sobre la recogida de Datos y su tratamiento</h2>
    <h3>Defensa jurídica</h3>
    <p>Los Datos Personales del Usuario podrán ser utilizados para la defensa jurídica del Titular en juicio o en las fases prejudiciales previas a un posible pleito derivado del uso abusivo por parte del Usuario de esta Aplicación o de los servicios relacionados. <br>El Usuario es consciente de que el Titular puede ser requerido por autoridades públicas a fin de revelar Datos Personales.</p>
    <h3>Información adicional acerca de los Datos Personales del Usuario</h3>
    <p>Además de las informaciones contenidas en esta política de privacidad, esta Aplicación podrá proporcionar al Usuario información contextual relativa a servicios específicos o a la recogida y tratamiento de los Datos Personales.</p>
    <h3>Log del sistema y mantenimiento</h3>
    <p>Por motivos relativos al funcionamiento y el mantenimiento, esta Aplicación y cualesquiera otros servicios de terceros que se utilicen podrán recoger un Log del sistema, esto es, archivos que registren las interacciones y que pueden contener Datos Personales, tales como la dirección IP del Usuario.</p>
    <h3>Información no contenida en esta política de privacidad</h3>
    <p>Se podrá solicitar en cualquier momento información adicional sobre la recogida y el tratamiento de los Datos Personales al Responsable de Tratamiento. Encontrará la información de contacto al inicio del presente documento.</p>
    <h3>Ejercicio de los derechos por los Titulares de los Datos</h3>
    <p>Los titulares a los que se refieren los Datos Personales tienen derecho a obtener en cualquier momento la confirmación de que estos han sido almacenados por el Responsable de Tratamiento, a conocer su contenido y origen, a verificar su exactitud o solicitar que sean completados, cancelados, actualizados o rectificados, a que sean anonimizados o a que se bloqueen aquellos Datos Personales que están siendo tratados en contravención de las leyes, así como a oponerse a su tratamiento por cualquier motivo legítimo. Las solicitudes deberán remitirse al Responsable de Tratamiento utilizando los datos de contacto indicados anteriormente.</p>
    <p>Esta Aplicación no permite solicitudes “Do Not Track”. <br>Para determinar si cualquiera de los servicios de terceros que utiliza acepta solicitudes “Do Not Track”, por favor lea sus políticas de privacidad.</p>
    <h3>Modificación de esta política de privacidad</h3>
    <p>El Responsable de Tratamiento se reserva el derecho de modificar esta política de privacidad en cualquier momento notificándolo a los Usuarios a través de esta página. Se recomienda encarecidamente que revisen esta página con frecuencia, tomando como referencia la fecha de la última modificación indicada al final. En el caso de que un Usuario esté en desacuerdo con alguna de las modificaciones realizadas a esta Política, el Usuario deberá cesar en el uso de esta Aplicación y podrá solicitar al Responsable de Tratamiento que elimine sus Datos Personales. Salvo que se indique lo contrario, la política de privacidad vigente en cada momento será de aplicación a todos los Datos Personales que el Responsable de Tratamiento haya recogido hasta entonces.</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="accent" mat-dialog-close>Cerrar</button>
</mat-dialog-actions>