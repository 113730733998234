import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {UnauthenticatedGuard} from './main/config/guards/unauthenticated.guard';
import {AdminGuard} from './main/config/guards/admin.guard';
import {ClientGuard} from './main/config/guards/client.guard';
import {CarrierGuard} from './main/config/guards/carrier.guard';

const appRoutes: Routes = [
    {
        path      : 'auth',
        loadChildren: () => import('./main/pages/authentication/authentication.module').then(m => m.AuthenticationModule),
        // canActivate: [UnauthenticatedGuard]
    },
    {
        path      : 'admin',
        loadChildren: () => import('./main/pages/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AdminGuard]
    },
    {
        path      : 'carrier',
        loadChildren: () => import('./main/pages/carrier/carrier.module').then(m => m.CarrierModule),
        canActivate: [CarrierGuard]
    },
    {
        path      : '',
        loadChildren: () => import('./main/pages/client/client.module').then(m => m.ClientModule),
        canActivate: [ClientGuard]
    },
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: [
        UnauthenticatedGuard,
        AdminGuard,
        CarrierGuard,
        ClientGuard,
    ]
})
export class RoutingModule { }