import { Injectable } from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Helpers} from '../../main/config/helpers';
import {Client} from '../models/users/clients/client';
import {PaginationResponse} from '../models/pagination/pagination-response.model';
import {ClientIssuedCertificate} from "../models/users/clients/client-issued-certificate";
import {ClientCertificate} from "../models/users/clients/client-certificate";

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    private baseEndPoint = environment.baseEndPoint + '/client';

    constructor(private http: HttpClient) { }

    getSubClients(id){
      return this.http.get(this.baseEndPoint + '/sub-clients/' + id, {headers: Helpers.commonHeaders()});
    }

    addSubClient(id, email){
      const data = {
        email: email,
        id: id,
      };
      return this.http.post(this.baseEndPoint + '/add-sub-client', data, {headers: Helpers.commonHeaders()});
    }

    removeSubClient(id, email){
      return this.http.get(this.baseEndPoint + '/remove/subclient/' + id + '/' + email, {headers: Helpers.commonHeaders()});
    }

    myAddresses() {
        return this.http.get(this.baseEndPoint + '/my/address', {headers: Helpers.commonHeaders()});
    }

    storeMyAddress(address: any) {
        return this.http.post(this.baseEndPoint + '/my/address', {address}, {headers: Helpers.commonHeaders()});
    }

    updateMyAddress(id: number, address: any) {
        return this.http.put(this.baseEndPoint + '/my/address/' + id, {address}, {headers: Helpers.commonHeaders()});
    }

    deleteMyAddress(id: number) {
        return this.http.delete(this.baseEndPoint + '/my/address/' + id, {headers: Helpers.commonHeaders()});
    }

    index(options?: any) {
        return this.http.get<Client[]>(this.baseEndPoint, {headers: Helpers.commonHeaders(), params: options});
    }

    show(id: number, options?: any) {
        return this.http.get<Client>(this.baseEndPoint + '/' + id,{headers: Helpers.commonHeaders(), params: options})
    }

    updatePassword(client: number, data){
        return this.http.put(this.baseEndPoint + '/' + client + '/password', data, {headers: Helpers.commonHeaders()});
    }

    pagination(page: number, options?: any) {
        options = options ? options : {};
        options.page = page;
        return this.http.get<PaginationResponse>(this.baseEndPoint + '/pagination', {headers: Helpers.commonHeaders(), params: options});
    }

    list(options?: any) {
        return this.http.get(this.baseEndPoint + '/list', {headers: Helpers.commonHeaders(), params: options});
    }

    get(client: number) {
        return this.http.get<Client>(this.baseEndPoint + '/' + client, {headers: Helpers.commonHeaders()});
    }

    create(client: any) {
        return this.http.post(this.baseEndPoint, client, {headers: Helpers.commonHeaders()});
    }

    update(client: number, data: any) {
        return this.http.put(this.baseEndPoint + '/' + client, data, {headers: Helpers.commonHeaders()});
    }

    destroy(client: number) {
        return this.http.delete(this.baseEndPoint + '/' + client, {headers: Helpers.commonHeaders()});
    }

    toggleStatus(client: number) {
        return this.http.put(this.baseEndPoint + '/' + client + '/status/toggle',null,{headers: Helpers.commonHeaders()})
    }

    storePickup(data: any) {
        return this.http.post(this.baseEndPoint + '/pickup', data, {headers: Helpers.commonHeaders()});
    }

    paginationPickup(data: any, page: number) {
        return this.http.get(this.baseEndPoint + '/pickup/pagination?page=' + page, {headers: Helpers.commonHeaders(), params: data});
    }

    showPickup(pickup: number, options?: any) {
        return this.http.get(this.baseEndPoint + '/pickup/' + pickup, {headers: Helpers.commonHeaders(), params: options});
    }

    states() {
        return this.http.get(this.baseEndPoint + '/state', {headers: Helpers.commonHeaders()});
    }

    carriers() {
        return this.http.get(this.baseEndPoint + '/carrier', {headers: Helpers.commonHeaders()});
    }

    wastes() {
        return this.http.get(this.baseEndPoint + '/waste', {headers: Helpers.commonHeaders()});
    }

    toggleCertificateStatus(certificate: number, options: any){
        return this.http.put(this.baseEndPoint + '/certificate/' + certificate + '/status/toggle', options, {headers: Helpers.commonHeaders()});
    }

    issuedCert(issuedCert: number){
        return this.http.get<ClientIssuedCertificate[]>(this.baseEndPoint + '/' + issuedCert + '/issued-certificate', {headers: Helpers.commonHeaders()});
    }

    createCert(client: number){
        return this.http.put(this.baseEndPoint + '/' + client + '/create-certificate',null,{headers: Helpers.commonHeaders()});
    }

    doIssueCert(client: number, data: any){
        return this.http.put<ClientIssuedCertificate[]>(this.baseEndPoint + '/' + client + '/issued-certificate',data,{headers: Helpers.commonHeaders()});
    }

    deleteIssuedCert(issuedCert: number){
        return this.http.delete(this.baseEndPoint + '/' + issuedCert + '/issued-certificate', {headers: Helpers.commonHeaders()});
    }

    destroyCert(client: number) {
        return this.http.delete(this.baseEndPoint + '/' + client + '/delete-certificate', {headers: Helpers.commonHeaders()});
    }

    renewCert(client: number) {
        return this.http.get<ClientCertificate>(this.baseEndPoint + '/' + client + '/renew-certificate', {headers: Helpers.commonHeaders()});
    }

    destroyAllIssueCerts(client: number) {
        return this.http.delete(this.baseEndPoint + '/' + client + '/delete-all-issues', {headers: Helpers.commonHeaders()});
    }

    downloadCert(client: number, issue: number) {
        let url = this.baseEndPoint + '/' + client + '/download-certificate?issue=' + issue;
        let headers = Helpers.commonHeaders();
        headers = headers.set('Accept', 'application/pdf')
        this.http.get(url,{
            headers:headers,
            responseType: 'blob',
        }).subscribe((response) => { // download file
            // var blob = new Blob([response.blob()], {type:doc.mime});
            var FileSaver = require('file-saver');
            let filename = 'Certificado PG#' + issue;
            FileSaver.saveAs(response,filename+'.pdf');
        });
    }
}
